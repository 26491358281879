import React, { useEffect } from "react";
import store from "./store";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { loadUser } from "./Wrapper/actions/auth";
import PrivateRoute from "./Wrapper/layout/PrivateRoute";
import Wrapper from "./Wrapper/layout/index";
import ModalsListener from "./Wrapper/components/ModalsListener/ModalsListener";
import Login from "./Login/Login";
import "./App.css";

const hist = createBrowserHistory();

export const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
    // document.title has been moved in index.js in layout
  }, []);

  return (
    <Provider store={store}>
      <ModalsListener></ModalsListener>
      <Router history={hist}>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login}></Route>
            <PrivateRoute path="/smartapp" component={Wrapper}></PrivateRoute>
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
