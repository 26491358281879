import React, { useState } from "react";
import logo from "../Wrapper/images/ferrero-logo-login.png";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { login } from "../Wrapper/actions/auth";
import Particles from "react-tsparticles";
import "./Login.css";

export const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    props.login(username, password);
  };

  if (props.isAuthenticated) {
    return <Redirect to="/smartapp"></Redirect>;
  }
  return (
    <div className="wrapperLogin">
      <Particles
        canvasClassName="example"
        options={{
          fpsLimit: 100,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: "repulse",
              },
            },
            modes: {
              repulse: {
                distance: 100,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 2,
              straight: false,
            },
            number: {
              value: 20,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: 1,
            },
          },
        }}
      />
      <div
        style={{
          width: "300px",
          height: "192px",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            display: "block",
            margin: "auto",
            width: "250px",
            marginBottom: "20px",
          }}
        />
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="inputLogin"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="inputLogin"
          />
          <button className="btnLogin" type="submit">
            Let me in
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
})(Login);
