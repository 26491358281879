import React from "react";
import { connect } from "react-redux";

export const CardProductApp = (props) => {
  var styleCardProduct = {
    border: `4px solid ${props.color}`,
    cursor: "pointer",
  };
  if (window.innerWidth < 800) {
    styleCardProduct = { ...styleCardProduct, height: "65px" };
  }
  if (!props.pic.enable[props.app]) {
    styleCardProduct = {
      ...styleCardProduct,
      cursor: "default",
      opacity: 0.9,
      border: `3px solid gray`,
    };
  }
  return (
    <a
      href={
        props.pic.enable[props.app]
          ? `${props.url[props.app]}?auth=${localStorage.getItem("token")}`
          : null
      }
    >
      <div
        className={props.pic.enable[props.app] ? "cardProduct" : "disabledCard"}
        style={styleCardProduct}
      >
        <h3 className="titleCardProduct" style={{ fontSize: 20 }}>
          {window.innerWidth > 800
            ? props.pic.title_long
            : props.pic.title_short}
        </h3>
        <div className="barCardProduct">
          {window.innerWidth > 800 ? <div className="emptybar"></div> : null}
          <div
            className="filledbar"
            style={{
              background: `linear-gradient(
                        90deg,
                        ${props.color} 0%,
                        ${props.color} 65%,
                        ${props.color} 100%
                    )`,
            }}
          ></div>
        </div>
        {window.innerWidth > 800 ? (
          <img
            src={props.pic.pic}
            alt={props.pic.title_short}
            className="picCard"
            style={props.pic.style}
          ></img>
        ) : null}
      </div>
    </a>
  );
};

// const mapStateToProps = state => ({

// })

export default connect(null, {})(CardProductApp);
