import React, { Component } from "react";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button } from "reactstrap";

export class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { errors } = this.props;
    if (errors !== prevProps.errors) {
      this.setState({ showModal: true });
    }
  }
  render() {
    const { errors } = this.props;
    return (
      <React.Fragment>
        <SweetAlert
          danger
          title="Errore"
          show={this.state.showModal}
          onConfirm={() => this.setState({ showModal: false })}
          customButtons={
            <React.Fragment>
              <Button
                color="danger"
                style={{ marginLeft: 2 }}
                onClick={() => this.setState({ showModal: false })}
              >
                Chiudi
              </Button>
            </React.Fragment>
          }
        >
          {Object.keys(errors.msg).includes("detail")
            ? errors.msg.detail
            : Object.keys(errors.msg).includes("non_field_errors")
            ? "Credentials not valid"
            : Object.keys(errors.msg).includes("username")
            ? `Username: ${errors.msg.username}`
            : Object.keys(errors.msg).includes("password")
            ? `Password: ${errors.msg.password}`
            : null}
        </SweetAlert>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps)(Alert);
