export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const USER_LOADING = "USER_LOADING";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GET_ERRORS = "GET_ERRORS";

// export const API_URL = "http://ferrero-smartapp.gdpanalytics.local:8000/api";
// export const API_URL_SKB_SST = "http://localhost:4001/";
// export const API_URL_SKB_SPT = "http://localhost:4002/";
// export const API_URL_RAF_SST = "http://localhost:4003/";
// export const API_URL_RAF_SPT = "http://localhost:4004/";

// export const API_URL = "http://ferrero-smartapp.gdpanalytics.local:8000/api";
// export const API_URL_SKB_SST = "http://ferrero-smartapp.gdpanalytics.local/skb/sst";
// export const API_URL_SKB_SPT = "http://ferrero-smartapp.gdpanalytics.local/skb/spt";
// export const API_URL_RAF_SST = "http://ferrero-smartapp.gdpanalytics.local/raf/sst";
// export const API_URL_RAF_SPT = "http://ferrero-smartapp.gdpanalytics.local/raf/spt";

// export const API_URL = "https://sstbelgium.ferrero.com/api";
// export const API_URL_SKB_SST = "https://sstbelgium.ferrero.com/skb/sst";
// export const API_URL_SKB_SPT = "https://sstbelgium.ferrero.com/skb/spt";
// export const API_URL_RAF_SST = "https://sstbelgium.ferrero.com/raf/sst";
// export const API_URL_RAF_SPT = "https://sstbelgium.ferrero.com/raf/spt";

export const API_URL = "https://smartapp.gdpanalytics.com/api";
export const API_URL_SKB_SST = "https://smartapp.gdpanalytics.com/skb/sst";
export const API_URL_SKB_SPT = "https://smartapp.gdpanalytics.com/skb/spt";
export const API_URL_RAF_SST = "https://smartapp.gdpanalytics.com/raf/sst";
export const API_URL_RAF_SPT = "https://smartapp.gdpanalytics.com/raf/spt";