import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useWindowDimensions } from "../../utils/useWindowDimensions";
import EyeLoader from "./components/EyeLoader";
import AppSelectorCards from "./components/AppSelectorCards";
import "./AppSelector.css";

export const AppSelector = ({ auth, history }) => {
  const [visible, setVisible] = useState(false);
  const windowDimension = useWindowDimensions();

  setTimeout(() => {
    setVisible(true);
  }, 4300);
  // 4300

  return (
    <React.Fragment>
      {!visible && windowDimension.width > 800 ? (
        <EyeLoader></EyeLoader>
      ) : (
        <AppSelectorCards history={history}></AppSelectorCards>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AppSelector);
