import React, { useEffect } from "react";
import AppSelector from "../routes/AppSelector/AppSelector";

export const Wrapper = (props) => {
  useEffect(() => {
    document.title = "Smart App";
  }, []);

  return <AppSelector></AppSelector>;
};

export default Wrapper;
