import React, { useState } from "react";
import Particles from "react-tsparticles";
import { Row, Col, Container, Card, CardTitle, CardSubtitle } from "reactstrap";
import CardProductApp from "./CardProductApp";
import SKBpic from "../../../images/SKB.jpg";
import RAFFpic from "../../../images/RAFF.png";
import KINSORpic from "../../../images/KINSOR.png";
import KINMAXIpic from "../../../images/KINMAXI.png";
import Test from "../../../images/test.png";
import { useWindowDimensions } from "../../../utils/useWindowDimensions";
import { FaChartBar } from "react-icons/fa";
import { FaSitemap } from "react-icons/fa";
import {
  API_URL_SKB_SST,
  API_URL_SKB_SPT,
  API_URL_RAF_SST,
  API_URL_RAF_SPT,
} from "../../../actions/types";

export const AppSelectorCards = ({ history }) => {
  const windowDimension = useWindowDimensions();
  const [visibleCards, setVisibleCards] = useState(false);
  const [visibleCardsProducts, setVisibleCardsProducts] = useState(false);
  const [color, setColor] = useState(null);
  const [app, setApp] = useState(null);

  setTimeout(() => {
    setVisibleCards(true);
  }, 1500);
  // 1500

  const arrayPics = [
    {
      pic: SKBpic,
      title_long: "Schoko-bons",
      // pic: Test,
      // title_long: "Product 1",
      title_short: "SKB",
      style: { width: "55%", height: "auto", marginLeft: 42, marginTop: 90 },
      // style: { width: "35%", height: "auto", marginLeft: 62, marginTop: 90 },
      enable: { SPT: true, SST: true },
      url: { SPT: API_URL_SKB_SPT, SST: API_URL_SKB_SST },
    },
    {
      pic: RAFFpic,
      title_long: "Raffaello",
      // pic: Test,
      // title_long: "Product 2",
      title_short: "RAF",
      style: { width: "45%", height: "auto", marginLeft: 55, marginTop: 90 },
      // style: { width: "35%", height: "auto", marginLeft: 62, marginTop: 90 },
      enable: { SPT: true, SST: true },
      url: { SPT: API_URL_RAF_SPT, SST: API_URL_RAF_SST },
    },
    {
      pic: KINSORpic,
      title_long: "Kinder Sorpresa",
      // pic: Test,
      // title_long: "Product 3",
      title_short: "SOR",
      style: { width: "25%", height: "auto", marginLeft: 72, marginTop: 90 },
      // style: { width: "35%", height: "auto", marginLeft: 62, marginTop: 90 },
      enable: { SPT: false, SST: false },
    },
    {
      pic: KINMAXIpic,
      title_long: "Kinder Maxi",
      // pic: Test,
      // title_long: "Product 4",
      title_short: "MAXI",
      style: { width: "36%", height: "auto", marginLeft: 63, marginTop: 90 },
      // style: { width: "35%", height: "auto", marginLeft: 62, marginTop: 90 },
      enable: { SPT: false, SST: false },
    },
  ];

  return (
    <div>
      <div className="divsmartAppBackground smartAppBackground">
        <Particles
          options={{
            fpsLimit: 100,
            interactivity: {
              events: {
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
              },
              modes: {
                repulse: {
                  distance: 100,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                value: 10,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: 1,
              },
            },
          }}
        />
        <div className="SmartAppTitle">
          <h1 className="home-title">
            <div>
              <span
                style={{
                  fontFamily: "Arial",
                  color: "rgb(209, 161, 54)",
                  fontWeight: "bold",
                  float: "left",
                }}
              >
                SMART&nbsp;
              </span>
              <span style={{ fontFamily: "Arial", fontWeight: "bold" }}>
                App
              </span>
            </div>
          </h1>
        </div>
      </div>
      {visibleCards ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            marginTop: windowDimension.height < 800 ? 200 : 240,
          }}
        >
          <div
            style={{
              marginRight: "11%",
              width: "380px",
            }}
          >
            <Card
              body
              className="appSelectorCard"
              style={
                color === "rgb(102, 184, 255)"
                  ? {
                      border: "3px solid #66B8FF",
                      borderRadius: 20,
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "rgb(102, 184, 255)",
                      transition: "1s",
                    }
                  : {
                      border: "3px solid #66B8FF",
                      borderRadius: 20,
                      cursor: "pointer",
                      transition: "1s",
                    }
              }
              onClick={() => {
                setVisibleCardsProducts(true);
                setColor("rgb(102, 184, 255)");
                setApp("SPT");
              }}
            >
              <CardTitle
                style={{ margin: "auto", fontSize: 24, fontWeight: "bold" }}
              >
                SPT
              </CardTitle>
              <CardSubtitle
                style={{ margin: "auto", fontSize: 17, marginTop: -5 }}
              >
                Smart Planning Tool
              </CardSubtitle>
              {windowDimension.width > 800 ? (
                <FaChartBar
                  style={
                    color === "rgb(102, 184, 255)"
                      ? {
                          color: "#4a4a4a",
                          fontSize: 45,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 30,
                          marginBottom: 20,
                          color: "white",
                          transition: "1s",
                        }
                      : {
                          color: "#4a4a4a",
                          fontSize: 45,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 30,
                          marginBottom: 20,
                          color: "#2C96F2",
                          transition: "1s",
                        }
                  }
                ></FaChartBar>
              ) : null}
            </Card>
          </div>
          <div style={{ width: "380px" }}>
            <Card
              body
              className="appSelectorCard"
              style={
                color === "rgb(209, 161, 54)"
                  ? {
                      border: "3px solid rgb(209, 161, 54)",
                      borderRadius: 20,
                      cursor: "pointer",
                      transition: "1s",
                      backgroundColor: "rgb(209, 161, 54)",
                      color: "white",
                    }
                  : {
                      border: "3px solid rgb(209, 161, 54)",
                      borderRadius: 20,
                      cursor: "pointer",
                      transition: "1s",
                    }
              }
              onClick={() => {
                setVisibleCardsProducts(true);
                setColor("rgb(209, 161, 54)");
                setApp("SST");
              }}
            >
              <CardTitle
                style={{ margin: "auto", fontSize: 24, fontWeight: "bold" }}
              >
                SST
              </CardTitle>
              <CardSubtitle
                style={{ margin: "auto", fontSize: 17, marginTop: -5 }}
              >
                Smart Scheduling Tool
              </CardSubtitle>
              {windowDimension.width > 800 ? (
                <FaSitemap
                  style={
                    color === "rgb(209, 161, 54)"
                      ? {
                          animationDuration: "20s",
                          color: "#4a4a4a",
                          fontSize: 50,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 30,
                          marginBottom: 10,
                          color: "rgb(209, 161, 54)",
                          transition: "1s",
                          color: "white",
                        }
                      : {
                          animationDuration: "20s",
                          color: "#4a4a4a",
                          fontSize: 50,
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginTop: 30,
                          marginBottom: 10,
                          color: "rgb(209, 161, 54)",
                          transition: "1s",
                        }
                  }
                ></FaSitemap>
              ) : null}
            </Card>
          </div>
        </div>
      ) : null}
      {visibleCardsProducts ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            width: "100%",
            marginTop: windowDimension.height < 800 ? 480 : 570,
          }}
        >
          <div className="containerCard">
            {arrayPics.map((pic) => (
              <CardProductApp
                key={pic.title_short}
                pic={pic}
                url={pic.url}
                windowDimension={windowDimension}
                color={color}
                app={app}
                history={history}
              ></CardProductApp>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AppSelectorCards;
