import axios from "axios";
import {
  USER_LOADED,
  AUTH_ERROR,
  API_URL,
  USER_LOADING,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_ERRORS,
} from "./types";
import { tokenConfig } from "../utils/tokenConfig";

// CHECK TOKEN & LOAD USER
export const loadUser = () => async (dispatch) => {
  try {
    // check always if the token is valid
    const res = await axios.get(`${API_URL}/auth/`, tokenConfig());
    dispatch({
      type: USER_LOADED,
    });
  } catch {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// LOGIN USER
export const login = (username, password) => async (dispatch) => {
  dispatch({ type: USER_LOADING });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ username, password });
  try {
    const res = await axios.post(`${API_URL}/auth/`, body, config);
    localStorage.setItem("token", res.data.token);
    const UserRequest = await axios.get(`${API_URL}/auth/`, tokenConfig());
    dispatch({
      type: LOGIN_SUCCESS,
      payload: UserRequest.data,
    });
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    const errors = {
      msg: err.response.data,
      status: err.response.status,
    };
    dispatch({
      type: GET_ERRORS,
      payload: errors,
    });
  }
};
